import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby";
import { Space, Row, Col } from 'antd';

import {showMobileAppNoti} from '../helpers/utils';
import './footer.less';
import logo from '../images/logo-text.png';
// import apple from '../images/button-app-store-apple.svg';
// import google from '../images/button-app-store-google-play.svg';
import facebook from '../images/icon-social-facebook.svg';
import linkedin from '../images/icon-social-linkedin.svg';
import twitter from '../images/icon-social-twitter.svg';

import Routes from '../constant/routes';

const Footer = ({  }) => (
  <footer className="Footer"
  >
    <div className="max-width-container Footer__Container">
      <Row gutter={[20, 40]}>
        <Col md={6}>
          <img src={logo} alt="logo" width={182} />
          {/* <div className="Body-LowerGreyLeft">TicketTamer is a mobile app and website that is designed to make it as easy as possible for you to take care of your traffic ticket and other citations.</div> */}
        </Col>
        <Col md={{span: 5, offset: 2}} xs={12}>
          <div className="LabelGreyLeft Footer__Title">Company</div>
          <Link to={Routes.TRAFFIC_TICKET_LAWYERS}>
            <div className="ButtonNavigationInactiveLeft Footer__Link">Services</div>
          </Link>
          <Link to={Routes.ABOUT_US}>
            <div className="ButtonNavigationInactiveLeft Footer__Link">About Us</div>
          </Link>
          <div className="ButtonNavigationInactiveLeft Footer__Link">Blog</div>
        </Col>
        <Col md={5} xs={12}>
          <div className="LabelGreyLeft Footer__Title">Support</div>
          <Link to={Routes.PRICING}><div className="ButtonNavigationInactiveLeft Footer__Link">Pricing</div></Link>
          <Link to={Routes.FAQ}><div className="ButtonNavigationInactiveLeft Footer__Link">FAQ</div></Link>
          <Link to={Routes.CONTACT_US} className="ButtonNavigationInactiveLeft Footer__Link">Contact</Link>
        </Col>
        <Col md={5} xs={12}>
          <div className="LabelGreyLeft Footer__Title">TERM AND PRIVACY</div>
          <Link to={Routes.PRIVACY_POLICY}><div className="ButtonNavigationInactiveLeft Footer__Link">Privacy Policy</div></Link>
          <Link to={Routes.LEGAL_NOTICES}><div className="ButtonNavigationInactiveLeft Footer__Link">Legal Notices</div></Link>
          <Link to={Routes.TERMS_OF_SERVICE}><div className="ButtonNavigationInactiveLeft Footer__Link">Terms of Service</div></Link>
        </Col>
        {/* <Col md={4} xs={12}>
          <div className="LabelGreyLeft Footer__Title">Get the app</div>
          <img src={apple} alt="app store" width={120} style={{marginBottom: 8, cursor: 'pointer'}} onClick={showMobileAppNoti} />
          <img src={google} alt="google play" width={120} onClick={showMobileAppNoti} style={{cursor: 'pointer'}} />
        </Col> */}
      </Row>
      <Row className="Footer__Watermark">
        <Col md={12}><div className="Body-LowerGreyLeft">© 2021 – TicketTamer | All Rights Reserved</div></Col>
        <Col md={12} style={{textAlign: 'right'}} className="for-desktop-block">
          <Space size={40}>
            <img src={facebook} alt="facebook" width={24} height={24} />
            <img src={linkedin} alt="linkedin" width={24} height={24} />
            <img src={twitter} alt="twitter" width={24} height={24} />
          </Space>
        </Col>
      </Row>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer;
