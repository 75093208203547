
import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import Footer from "./footer";
import Seo from './seo';

import "./layout.less";
import "../styles/index.less";
import "../styles/about-us.less";
import "../styles/contact-us.less";
import "../styles/pricing.less";
import "../styles/style.less";
import "../styles/canna-tamer-pricing.less";

const Layout = ({ children, style={}, title="", description }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="Layout">
      <Seo title={title} description={description} />
      <Header />
      <div className="Layout__Container" style={style}>
      {children}
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
