import {API_URL} from '../constant';

const BASE_URL = API_URL;

const handleResponse = async (res) => {
  return res;
};

export const get = (url) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return fetch(
    `${BASE_URL}/api/${url}`,
    {
      method: 'GET',
      headers,
      timeout: 10000,
    },
  ).then((response) => response.json())
  .then((res) => handleResponse(res));
};

export const post = (url, data = {}) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return fetch(
    `${BASE_URL}/api/${url}`,
    {
      method: 'POST',
      headers,
      timeout: 10000,
      body: JSON.stringify(data),
    },
  ).then((response) => response.json())
  .then((res) => handleResponse(res));
};

export const sendCaseUpdate = data => {
    return post(`caseUpdate/sendRequest`, data);
}

export const getFreeMarijuana = data => {
  return post('consumer/marijuana/submitRequest', data);
}