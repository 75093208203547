import {useEffect, useState} from 'react';
import {Modal} from 'antd';

export const showMobileAppNoti = () => {
    Modal.info({content: 'TicketTamer Mobile App is coming soon, hang tight!'});
}

export const useOnScreen = (ref) => {
    const [isIntersecting, setIntersecting] = useState(false)
    let observer = null
  
    useEffect(() => {
      observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
      )
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
    }, []);
  
    return isIntersecting
  }