import Routes from './routes';

export const WEB_APP_URL='https://app.tickettamer.com';
export const GET_TICKETTAMER='https://get.tickettamer.com/';
export const API_URL = 'https://api.tickettamer.com';
export const LANDING_URL='https://tickettamer.com';

export const ALL_SERVICES = [
  {route: Routes.TRAFFIC_TICKET_LAWYERS, title: "Traffic Ticket Lawyers"},
  {route: Routes.SPEEDING_TICKET_LAWYERS, title: "Speeding Ticket Lawyers"},
  {route: Routes.CARELESS_DRIVING_LAWYERS, title: "Careless Driving Lawyers"},
  {route: Routes.CDL_LAWYERS, title: "CDL Lawyers"},
  {route: Routes.SUSPENDED_LICENSE_LAWYERS, title: "Suspended License Lawyers"},
  {route: Routes.FAKE_ID_LAWYERS, title: "Fake Id Lawyers"},
  {route: Routes.MIP_LAWYERS, title: "MIP Lawyers"},
  {route: Routes.DUI_LAWYERS, title: "DWI or DUI Lawyers"},
  {route: Routes.CANNA_TAMER_PRICING, title: "Marijuana"},
  {route: Routes.STOP_SIGN, title: "Stop Sign"},
  {route: Routes.RED_LIGHT, title: "Red Light"},
];
