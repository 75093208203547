const Routes = {
  HOME: "/",
  ABOUT_US: "/about-us",
  PRICING: "/pricing",
  FAQ: "/faq",
  CONTACT_US: "/contact-us",
  PRIVACY_POLICY: "/privacy-policy",
  LEGAL_NOTICES: "/legal-notices",
  RESOURCES: "/resources",
  TERMS_OF_SERVICE: "/terms-of-service",
  TRAFFIC_TICKET_LAWYERS: "/services/traffic-ticket-lawyers",
  SPEEDING_TICKET_LAWYERS: "/services/speeding-ticket-lawyers",
  CARELESS_DRIVING_LAWYERS: "/services/careless-driving-lawyers",
  CDL_LAWYERS: "/services/cdl-lawyers",
  SUSPENDED_LICENSE_LAWYERS: "/services/suspended-license-lawyers",
  FAKE_ID_LAWYERS: "/services/fake-id-lawyers",
  MIP_LAWYERS: "/services/mip-lawyers",
  DUI_LAWYERS: "/services/dui-lawyers",
  CANNA_TAMER: "/services/canna-tamer",
  CANNA_TAMER_PRICING: "/canna-tamer-pricing",
  STOP_SIGN: "/services/stop-sign",
  RED_LIGHT: "/services/red-light",
  POSSESSION_AND_PARAPHERNALIA_LAWYERS: "/"
}

export default Routes;
